import _ from "lodash";
import { DateTime } from "luxon";
import moment from "moment-timezone";
import { isTemporallyAvailableForOrder, removeUndefinedFromObject } from "../../../functions/Helpers";
import { Catalog, DEFAULT_LANGUAGE, getTimezoneName, MIN_DEFAULT_PREPARATION_TIME } from "../../../model/Catalog";
import { Location, RequireCustomerInfo, SupportedServiceType, Table } from "../../../model/Location";
import { DISPLAY_ID_LENGTH, Order, OrderCharge, OrderChargeType, OrderInBase, OrderItem, OrderOption, OrderPayment, OrderStatus, PaymentType } from "../../../model/Order";
import { OrderError } from "../../../model/OrderError";
import { SignInProviders } from "../../authentications/models/BaseUser";
import { Customer } from "../../authentications/models/Customer";
import { Money, MoneyToStringWithSymbol, substractMoney } from "../../common/models/Money";
import { log, removeLogInPreprodOrProd } from "../../common/services/LogService";
import { CONNECTORS_SUPPORTING_ADDING_ITEMS } from "../../connectors/configs/ConnectorsSupportConfig";
import { DEFAULT_DELIVERY_TIME_UNIT } from "../../delivery/configs/DefaultDeliveryVariable";
import deliveryHelper from "../../delivery/services/DeliveryHelper";
import { OrderDiscount } from "../../discounts/models/OrderDiscount";
import { getLocationFirestoreDocPath } from "../../locations/services/LocationService";
import { Restriction } from "../../restrictions/model/Restriction";
import { ENDING_HOUR, getAllowedOrderTimeSlots, getDayNumberFromMoment, getFirstMatchingRestriction, getValidDate, STARTING_HOUR } from "../../restrictions/services/RestrictionsService";
import L from "../../translations/locales/i18n-node";
import { Locales } from "../../translations/locales/i18n-types";
import { locales } from "../../translations/locales/i18n-util";
import { FIRESTORE_ORDERS_COLLECTION } from "../configs/OrdersConfig";
import OrderContributor from "../models/OrderContributor";
import OrderDisplayItem, { OrderDisplayItemType } from "../models/OrderDisplay";
import OrderDisplay from "../models/OrderDisplayItem";
import { OrderDealError, OrderItemError } from "../models/OrderItemError";
import { OrderRefusalReason } from "../models/OrderRefusalReason";
import OrderTimeSlot from "../models/OrderTimeSlot";
import { PreparationTimeOverride } from "../models/PreparationTimeOverride";
import { DEFAULT_COLLECTION_SLOT_MINUTES } from "./CollectionService";

/**
 * Return two list of item entities
 * disabled Deals / disabled Items
 * 
 * Check also category and parent category disable for single item (not include in deal)
 * @param order 
 * @param catalog 
 * @returns 
 */
export const getDisabledEntities = (order: Order, catalog: Catalog, patchOrder?: boolean) => {
    const disableDeal: OrderDealError[] = []
    const disableItem: OrderItemError[] = []

    const orderDeals = order.deals

    // * Check disable for deals
    if (order.deals) {

        Object.keys(orderDeals).forEach(dealKey => {

            // TODO: add an update id to deals
            const usedDeal = catalog.data.deals.find(deal => deal.ref === orderDeals[dealKey].ref);
            if (!usedDeal) {
                log.error(`Deal ${orderDeals[dealKey].ref} not found in catalog ${catalog.id}`)
            }

            if (!usedDeal || usedDeal.disable) {

                const disableDealError: OrderDealError = {
                    ..._.cloneDeep(orderDeals[dealKey]),
                    deal_key: dealKey,
                    refusal_reason: OrderRefusalReason.ENTITY_DISABLE
                }

                disableDeal.push(disableDealError)

                if (patchOrder) {
                    delete order.deals[dealKey]
                }
            }

        })

    }
    const newOrderItem: OrderItem[] = []

    // * Check disable for items && category
    order.items.forEach((item, index) => {
        // Check only items not yet sent
        if (!item.update_id) {
            checkIfEntityDisabledAndFillArrays(
                item,
                index,
                disableDeal,
                disableItem,
                catalog,
            )

            if (!disableItem.find(item => item.index === index)) {
                newOrderItem.push(item)
            }
        } else {
            newOrderItem.push(item);
        }
    })
    if (patchOrder) {
        order.items = newOrderItem
    }

    return {
        disableDeals: disableDeal,
        disableItems: disableItem
    }
}

const checkIfEntityDisabledAndFillArrays = (
    item: OrderItem,
    index: number,
    disableDeal: OrderDealError[],
    disableItem: OrderItemError[],
    catalog: Catalog,
) => {

    if (item.deal_line && item.deal_line.deal_key && disableDeal.find(deal => deal.deal_key === item.deal_line?.deal_key)) {

        const orderItemError = _.cloneDeep(item) as OrderItemError;
        orderItemError.refusal_reason = OrderRefusalReason.IN_INVALID_DEAL;
        orderItemError.index = index;

        disableItem.push(orderItemError);
        return;
    }

    const product = catalog.data.products.find(pdt => pdt.ref === item.product_ref)
    const sku = product?.skus.find(sku => sku.ref === item.sku_ref);

    if (!product) {
        removeLogInPreprodOrProd(`product ${item.product_ref} not found in catalog ${catalog.id}`);
    }
    if (!sku) {
        removeLogInPreprodOrProd(`sku ${item.sku_ref} not found in product ${item.product_ref} in catalog ${catalog.id}`);
    }

    if (!product || product.disable || !sku || sku.disable) {

        const orderItemError = _.cloneDeep(item) as OrderItemError;
        orderItemError.refusal_reason = OrderRefusalReason.ENTITY_DISABLE;
        orderItemError.index = index;

        disableItem.push(orderItemError);
        return;
    }

    if (!item.deal_line || !item.deal_line.deal_key) {

        const categoryRef = product.category_ref
        const productCategory = catalog.data.categories.find(cate => cate.ref === categoryRef)

        if (!productCategory) {
            log.error(`category ${categoryRef} not found in catalog ${catalog.id}`)
            return;
        }

        if (productCategory.disable) {

            const orderItemError = _.cloneDeep(item) as OrderItemError;
            orderItemError.refusal_reason = OrderRefusalReason.CATEGORY_DISABLED;
            orderItemError.index = index;

            disableItem.push(orderItemError);
            return;
        }

        if (productCategory.parent_ref) {

            const parentCategory = catalog.data.categories.find(cate => cate.ref === productCategory.parent_ref)

            if (!parentCategory) {
                log.error(`Parent category ${productCategory.parent_ref} not found in catalog ${catalog.id}`)
                return;
            }

            if (parentCategory?.disable) {

                const orderItemError = _.cloneDeep(item) as OrderItemError;
                orderItemError.refusal_reason = OrderRefusalReason.CATEGORY_DISABLED;
                orderItemError.index = index;

                disableItem.push(orderItemError);
                return;
            }
        }
    }

    // Checking options
    const disabledOptionNames: string[] = [];
    item.options?.forEach((optionFromItem) => {

        const optionFromCatalog = catalog.data.options.find(o => o.ref === optionFromItem.ref);
        if (optionFromCatalog && optionFromCatalog.disable) {
            disabledOptionNames.push(optionFromCatalog.name);
        }
    });

    if (disabledOptionNames.length > 0) {

        const orderItemError = _.cloneDeep(item) as OrderItemError;
        orderItemError.refusal_reason = OrderRefusalReason.OPTION_DISABLED;
        orderItemError.index = index;
        orderItemError.disabled_option_names = disabledOptionNames;
        disableItem.push(orderItemError);
        return;
    }

}

/**
 * Return two list of item entities
 * unavailable Deals / unavailable Items
 * 
 * Check also category and parent category unavailable for single item (not include in deal)
 * 
 * Only based on restrictions
 * @param order 
 * @param catalog 
 * @returns 
 */
export const getUnavailableEntities = (order: Order, catalog: Catalog, patchOrder?: boolean) => {

    const unavailableDeals: OrderDealError[] = []
    const unavailableItems: OrderItemError[] = []

    const orderDeals = order.deals

    // * Check unavailable for deals
    if (order.deals) {

        Object.keys(orderDeals).forEach(dealKey => {

            // TODO: do not check if update id
            const usedDeal = catalog.data.deals.find(deal => deal.ref === orderDeals[dealKey].ref);
            if (!usedDeal) {
                log.error(`Deal ${orderDeals[dealKey].ref} not found in catalog ${catalog.id}`)
            } else if (usedDeal && usedDeal.restrictions) {
                const isAvailable = isTemporallyAvailableForOrder(order, getTimezoneName(catalog), usedDeal.restrictions, true);
                if (!isAvailable) {

                    const unavailableDealError: OrderDealError = {
                        ..._.cloneDeep(orderDeals[dealKey]),
                        deal_key: dealKey,
                        refusal_reason: OrderRefusalReason.ENTITY_UNAVAILABLE
                    };
                    unavailableDeals.push(unavailableDealError)

                    if (patchOrder) {

                        delete order.deals[dealKey]

                    }


                }
            }
        })

    }

    const newOrderItem: OrderItem[] = []
    // * Check unavailable for items & categories
    order.items.forEach((item, index) => {

        // Check only items not yet sent
        if (!item.update_id) {
            const product = catalog.data.products.find(pdt => pdt.ref === item.product_ref)
            if (!product) {
                log.error(`product ${item.product_ref} not found in catalog ${catalog.id}`)
            } else {

                if (product.restrictions) {

                    const isAvailable = isTemporallyAvailableForOrder(order, getTimezoneName(catalog), product.restrictions, true);
                    if (!isAvailable) {
                        const unavailableItem = _.cloneDeep(item) as OrderItemError;
                        unavailableItem.refusal_reason = OrderRefusalReason.ENTITY_UNAVAILABLE;
                        unavailableItem.index = index;
                        unavailableItems.push(unavailableItem)
                    }

                }

                if (!item.deal_line || !item.deal_line.deal_key) {

                    const categoryRef = product.category_ref
                    const productCategory = catalog.data.categories.find(cate => cate.ref === categoryRef)
                    if (!productCategory) {
                        log.error(`category ${categoryRef} not found in catalog ${catalog.id}`)
                    } else {

                        if (productCategory.restrictions) {
                            const isAvailable = isTemporallyAvailableForOrder(order, getTimezoneName(catalog), productCategory.restrictions, true);
                            if (!isAvailable) {
                                const orderItemError = _.cloneDeep(item) as OrderItemError
                                orderItemError.refusal_reason = OrderRefusalReason.CATEGORY_UNAVAILABLE
                                orderItemError.index = index;
                                unavailableItems.push(orderItemError)
                            }
                        }

                        if (productCategory.parent_ref) {

                            const parentCategory = catalog.data.categories.find(cate => cate.ref === productCategory.parent_ref)

                            if (!parentCategory) {
                                log.error(`Parent category ${productCategory.parent_ref} not found in catalog ${catalog.id}`)

                            } else if (parentCategory.restrictions) {
                                const isAvailable = isTemporallyAvailableForOrder(order, getTimezoneName(catalog), parentCategory.restrictions, true);
                                if (!isAvailable) {
                                    const orderItemError = _.cloneDeep(item) as OrderItemError
                                    orderItemError.refusal_reason = OrderRefusalReason.CATEGORY_UNAVAILABLE
                                    orderItemError.index = index;
                                    unavailableItems.push(orderItemError)
                                }
                            }

                        }
                    }
                }
            }
            if (!unavailableItems.find(item => item.index === index)) {
                newOrderItem.push(item)
            }
        } else {
            newOrderItem.push(item);
        }
    })

    if (patchOrder) {
        order.items = newOrderItem
    }

    return {
        unavailableDeals: unavailableDeals,
        unavailableItems: unavailableItems
    }
}

export const getOrdersFirestoreCollectionPath = (accountId: string, locationId: string): string => {
    return `${getLocationFirestoreDocPath(accountId, locationId)}/${FIRESTORE_ORDERS_COLLECTION}`;
}

export const getOrderFirestoreDocPath = (accountId: string, locationId: string, orderId: string): string => {
    return `${getOrdersFirestoreCollectionPath(accountId, locationId)}/${orderId}`;
}

export const getFirestoreDocPath = (order: OrderInBase): string => {
    return getOrderFirestoreDocPath(order.account_id, order.location_id, order.id);
}

/**
 * Storage on google cloud
 * @param order 
 * @param extension 
 * @returns 
 */
export const getOrderBackupStoragePath = (order: OrderInBase, suffixWithExtension: string): string => {
    return `${getOrderFirestoreDocPath(order.account_id, order.location_id, order.id)}${suffixWithExtension}`;
}

export const SERVICE_TYPE_SUPPORTING_ADDING_ITEMS: SupportedServiceType[] = [SupportedServiceType.EAT_IN];
export const ORDER_STATUS_NOT_SUPPORTING_ADDING_ITEMS: OrderStatus[] = [OrderStatus.CANCELLED, OrderStatus.IN_DELIVERY, OrderStatus.COMPLETED, OrderStatus.DELIVERY_FAILED];
export const CHILD_ORDER_PAYMENT_INTENT_ID = "PARENT_ORDER"

export const orderService = {

    getOrderTotalWithoutTips(order: Order): Money {
        let totalWithoutTips = order.total;
        order.charges?.forEach((orderCharge) => {
            if (orderCharge.type === OrderChargeType.TIP) {
                totalWithoutTips = substractMoney(totalWithoutTips, orderCharge.price);
            }
        })
        return totalWithoutTips;
    },

    /**
     * TODO: unit test
     * @param customer 
     * @returns 
     */
    getOrderContributor(customer: Customer): OrderContributor {
        const contributor: OrderContributor = {};
        contributor.email = customer.email
        contributor.email_verified = customer.email_verified
        contributor.loyalty_balance = customer.loyalty_balance ? customer.loyalty_balance : 0;
        contributor.phone_verified = customer.phone_verified
        contributor.sign_in_provider = customer.sign_in_provider
        contributor.private_refs = customer.private_refs
        contributor.uid = customer.uid
        contributor.used_discounts = customer.used_discounts
        contributor.used_deals = customer.used_deals
        return contributor;
    },

    /**
     * TODO: to be tested
     * @param order
     * @param userId 
     * @returns 
     */
    isContributorAuthenticated(order: Pick<Order, "contributors" | "customer">, userId: string) {
        if (order.contributors && order.contributors[userId]) {
            const orderContributor = order.contributors[userId];
            return orderContributor.sign_in_provider && orderContributor.sign_in_provider !== SignInProviders.ANONYMOUS;
        } else if (order.customer?.uid === userId) {
            return order.customer?.sign_in_provider && order.customer?.sign_in_provider !== SignInProviders.ANONYMOUS;
        }
        // Not part of the contributors, return false
        return false;
    },

    affectServiceTypeRefPaymentRefs(order: Order, location: Location) {
        const serviceTypeRefs = location.connector?.parameters?.service_type_ref
        if (serviceTypeRefs && serviceTypeRefs[order.service_type]) {
            order.service_type_ref = serviceTypeRefs[order.service_type]
        } else {
            order.service_type_ref = order.service_type
        }

        order.payments?.forEach((payment) => {
            const foundSupportedPayment = location.supported_payment_types?.find((supportedPaymentType) =>
                supportedPaymentType.type === payment.payment_type
            );
            if (foundSupportedPayment) {
                payment.ref = foundSupportedPayment.ref;
            }
        })
    },

    /**
     * Is the order already sent to the api but not pending payment
    */
    alreadySentNotPendingPayment(order: Order): boolean {
        return orderService.alreadySent(order) && order.status !== OrderStatus.PENDING_PAYMENT;
    },

    /**
     * Is the order already sent to the api
     * WARING: maybe not yet sent to the connector though
     */
    alreadySent(order: Order): boolean {
        return !(order.status === OrderStatus.DRAFT || order.status === OrderStatus.WAITING_SUBMISSION);
    },

    /**
     * If the private ref is defined, the order has been sent to the POS
     * @param order 
     * @returns 
     */
    alreadySentToConnector(order: Order): boolean {
        return Boolean(order.private_ref);
    },

    /**
     * Once sent, an order can't be fully edited (only specific operation through API)
     * @param order
     */
    isFullyEditable(order: Pick<Order, "status">): boolean {
        return this.isFullyEditableStatus(order.status);
    },

    isFullyEditableStatus(status: OrderStatus): boolean {
        return status === OrderStatus.DRAFT || status === OrderStatus.WAITING_SUBMISSION;
    },

    /**
     * Tell if an order is DRAFT or PENDING_PAYMENT or WAITING_SUBMISSION
     * @param orderStatus 
     * @returns 
     */
    isOrderStatusDWP(orderStatus: OrderStatus): boolean {

        return (
            orderStatus === OrderStatus.DRAFT
            || orderStatus === OrderStatus.PENDING_PAYMENT
            || orderStatus === OrderStatus.WAITING_SUBMISSION
        )
    },


    /**
     * True if the order has items not yet sent to the backend (update id not filled)
     * @param order 
     */
    hasItemToBeAdded(order: Order): boolean {
        return order.items.find((orderItem) => !orderItem.update_id) ? true : false;
    },

    /**
     * Is the user allowed to add item to an order
     * TODO: to be tested
     */
    canAddItems(location: Location, order: Order, throwError?: boolean): boolean {

        // Order not yet finalized
        if (this.isFullyEditable(order)) {
            return true;
        } else { // Order finalized, should have been send to the POS if needed
            const editOpenedOrderAllowed = location.orders?.allow_edit_opened_orders ? true : false;
            if (!editOpenedOrderAllowed) { // Disabled by settings
                if (throwError) {
                    throw OrderError.ADD_ITEMS_NOT_ALLOWED.withValue({
                        allow_edit_opened_orders: editOpenedOrderAllowed
                    })
                } else {
                    return false;
                }
            } else {
                if (!SERVICE_TYPE_SUPPORTING_ADDING_ITEMS.includes(order.service_type)) {
                    if (throwError) {
                        throw OrderError.ADD_ITEMS_NOT_ALLOWED.withValue({
                            order_service_type: order.service_type,
                            supported_service_types: SERVICE_TYPE_SUPPORTING_ADDING_ITEMS
                        })
                    } else {
                        return false;
                    }
                } else {
                    if (ORDER_STATUS_NOT_SUPPORTING_ADDING_ITEMS.includes(order.status)) {
                        if (throwError) {
                            throw OrderError.ADD_ITEMS_NOT_ALLOWED.withValue({
                                order_status: order.status,
                                not_supported_order_status: ORDER_STATUS_NOT_SUPPORTING_ADDING_ITEMS
                            })
                        } else {
                            return false;
                        }
                    } else {
                        if (!location.connector?.type) { // NO POS, can add items
                            return true;
                        } else { // POS, check if the pos is supporting
                            if (CONNECTORS_SUPPORTING_ADDING_ITEMS.includes(location.connector.type)) { // POS not supporting adding payment
                                return true;
                            } else {
                                if (throwError) {
                                    throw OrderError.ADD_ITEMS_NOT_ALLOWED.withValue({
                                        connector_type: location.connector.type,
                                        supported_connector_types: CONNECTORS_SUPPORTING_ADDING_ITEMS
                                    })
                                } else {
                                    return false;
                                }
                            }
                        }
                    }
                }
            }
        }
    },

    /**
    * Get missing customer info in order
    * @param order
    * @param catalog 
    */
    getMissingCustomerInfo(order: Order, location: Location): string[] {
        const missingCustomerInfo: string[] = [];
        //TODO: Check validity of parameters
        const require_customer_info: RequireCustomerInfo | null = location.require_customer_info ? location.require_customer_info : null

        if (require_customer_info) { // if there is information to check, then check

            const customerInfoNeeded = require_customer_info[order.service_type] ? require_customer_info[order.service_type] : null

            if (customerInfoNeeded) {

                if (customerInfoNeeded.expected_time && !order.expected_time) {
                    missingCustomerInfo.push("expected_time");
                }
                if (customerInfoNeeded.email && !order.customer?.email) {
                    missingCustomerInfo.push("email");
                }
                if (customerInfoNeeded.first_name && !order.customer?.first_name) {
                    missingCustomerInfo.push("first_name");
                }
                if (customerInfoNeeded.phone && !order.customer?.phone) {
                    missingCustomerInfo.push("phone");
                }
            }
        }
        return missingCustomerInfo;
    },

    /**
     * Fill the order display info:
     * Group items by Deal
     * TODO: localize
     * TODO: unit test
     * CAUTIOUS: only sent items are included (having update id)
     */
    addDisplayInfo(order: OrderInBase, catalog: Catalog | undefined, tables: Table[] | null, hierarchical?: boolean, doNotCheckUpdateId?: boolean, selectedLocale?: Locales): OrderDisplay {

        if (!selectedLocale) {
            const language = order.language ?? (catalog?.language ? catalog?.language.toLowerCase() : DEFAULT_LANGUAGE);
            selectedLocale = language as Locales;
            if (!locales.includes(selectedLocale)) {
                selectedLocale = "en"
            }
        }

        const orderDisplay: OrderDisplay = {
            ...order,
            display_items: []
        }
        if (!order.display_id || order.display_id.length !== DISPLAY_ID_LENGTH || !order.collection_code || order.collection_code.length !== DISPLAY_ID_LENGTH) {
            orderDisplay.display_id = this.createOrderDisplayId(order.id)
            orderDisplay.collection_code = orderDisplay.display_id;
        }

        // Set expected time to created at for eat in
        if (orderDisplay.service_type === SupportedServiceType.EAT_IN && !orderDisplay.expected_time && orderDisplay.created_at) {
            orderDisplay.expected_time = orderDisplay.created_at;
        }

        // Fill table name if not yet filled
        if (!order.table_name && tables) {
            const foundTable = tables.find((table) => table.id === order.table_id);
            if (foundTable) {
                orderDisplay.table_name = foundTable.name;
                orderDisplay.table_ref = foundTable.ref;
            } else {
            }
        }

        // For each deal
        let hasDeals = false;
        if (order.deals) {
            const dealKeys = Object.keys(order.deals);
            hasDeals = dealKeys.length > 0;
            dealKeys.forEach((dealKey) => {
                const orderDeal = order.deals[dealKey];
                const foundDeal = catalog?.data?.deals?.find((deal) => deal.ref === orderDeal.ref);
                // Find related products
                const dealOrderItems = order.items?.filter((item) => item.deal_line && item.deal_line.deal_key === dealKey && (item.update_id || doNotCheckUpdateId));
                if (dealOrderItems && dealOrderItems.length) {
                    let name = orderDeal.name;
                    if (foundDeal) {
                        name = foundDeal.name;
                    } else if (catalog) {
                        log.debug(`Deal ${orderDeal.ref} not found in catalog ${catalog?.id} for account ${catalog?.account_id} and location ${catalog?.location_id}`);
                    }
                    if (!name) {
                        // TODO: localization
                        name = "Formule inconnue";
                    }
                    const dealDisplayItem: OrderDisplayItem = {
                        name: name,
                        subItems: [],
                        price: orderDeal.price ? MoneyToStringWithSymbol(orderDeal.price) : undefined,
                        type: OrderDisplayItemType.DEAL_CATEGORY
                    }
                    orderDisplay.display_items.push(dealDisplayItem);

                    dealOrderItems.forEach((item) => {
                        const displayItems = this.getOrderItemDisplayInfo(item, selectedLocale!, catalog, hierarchical);
                        if (hierarchical) {
                            displayItems.forEach(displayItem => dealDisplayItem.subItems?.push(displayItem));
                        } else {
                            displayItems.forEach(displayItem => orderDisplay.display_items.push(displayItem));
                        }
                    })
                }
            })
        }

        // Get items not part of a deal
        const noDealOrderItems = order.items?.filter((item) => (!item.deal_line || !item.deal_line.deal_key) && (item.update_id || doNotCheckUpdateId));
        if (noDealOrderItems && noDealOrderItems.length) {
            const orderDisplayItems: OrderDisplayItem[] = [];
            noDealOrderItems.forEach((orderItem) => {
                const displayItems = this.getOrderItemDisplayInfo(orderItem, selectedLocale!, catalog, hierarchical);
                displayItems.forEach(displayItem => orderDisplayItems.push(displayItem));
            })
            if (hasDeals) {
                // Create a specific category
                const noDealDisplayItem: OrderDisplayItem = {
                    name: L[selectedLocale!].orders_display_info_no_deal(),
                    subItems: [],
                    type: OrderDisplayItemType.NO_DEAL_CATEGORY
                }
                orderDisplay.display_items.push(noDealDisplayItem);
                orderDisplayItems.forEach(orderDisplayItem => {
                    if (hierarchical) {
                        noDealDisplayItem.subItems?.push(orderDisplayItem);
                    } else {
                        orderDisplay.display_items.push(orderDisplayItem);
                    }
                })

            } else {
                orderDisplay.display_items = orderDisplayItems;
            }
        }

        orderDisplay.discounts?.forEach((discount) => {
            orderDisplay.display_items.push({
                name: discount.name ?? discount.ref,
                price: "-" + MoneyToStringWithSymbol(discount.price_off),
                type: OrderDisplayItemType.DISCOUNT,
            });
        });

        orderDisplay.charges?.forEach((charge) => {
            orderDisplay.display_items.push({
                name: charge.name ?? charge.ref,
                price: MoneyToStringWithSymbol(charge.price),
                type: charge.type === OrderChargeType.TIP ? OrderDisplayItemType.TIP : OrderDisplayItemType.CHARGE,
            });
        });

        if (orderDisplay.customer_notes) {
            orderDisplay.display_items.push({
                name: L[selectedLocale].orders_comment({ comment: orderDisplay.customer_notes }),
                type: OrderDisplayItemType.CUSTOMER_NOTES,
            });
        }

        // Add TOTAL
        orderDisplay.display_items.push({
            name: L[selectedLocale].orders_total(),
            type: OrderDisplayItemType.TOTAL,
            price: MoneyToStringWithSymbol(order.total)
        });

        return orderDisplay;
    },

    getOrderItemDisplayInfo(
        orderItem: OrderItem,
        selectedLocale: Locales,
        catalog: Catalog | undefined,
        hierarchical?: boolean
    ): OrderDisplayItem[] {

        const displayItems: OrderDisplayItem[] = [];
        const foundProduct = catalog?.data?.products?.find((product) => product.ref === orderItem.product_ref);

        let name = orderItem.product_name
        if (orderItem.sku_name) {
            if (!orderItem.sku_name.includes(name)) {
                name += ` ${orderItem.sku_name}`;
            } else {
                name = orderItem.sku_name;
            }
        }

        if (foundProduct) {
            name = foundProduct.name;
            const foundSku = foundProduct.skus?.find((sku) => sku.ref === orderItem.sku_ref);
            if (foundSku) {
                if (foundSku.name) {
                    if (!foundSku.name.includes(foundProduct.name)) {
                        name = `${foundProduct.name} ${foundSku.name}`;
                    } else {
                        name = foundSku.name;
                    }
                }
            } else if (catalog) {
                log.debug(`Sku ${orderItem.sku_ref} not found in catalog ${catalog?.id} for account ${catalog?.account_id} and location ${catalog?.location_id}`);
            }
        } else if (catalog) {
            log.debug(`Product ${orderItem.product_ref} not found in catalog ${catalog?.id} for account ${catalog?.account_id} and location ${catalog?.location_id}`);
        }
        if (!name) {
            // TODO: translate it
            name = "Produit inconnu";
        }

        const orderDisplayItem: OrderDisplayItem = {
            name: name,
            price: orderItem.price ? MoneyToStringWithSymbol(orderItem.price) : undefined,
            quantity: orderItem.quantity,
            type: OrderDisplayItemType.ORDER_ITEM
        }
        displayItems.push(orderDisplayItem);

        // Add options
        if (orderItem.options) {
            const optionNames: string[] = [];

            orderItem.options?.forEach((orderItemOption) => {

                let name = orderItemOption.name;
                const foundOption = catalog?.data?.options.find((option) => option.ref === orderItemOption.ref);

                if (foundOption) {
                    name = foundOption.name;
                } else if (catalog) {
                    log.debug(`Option ${orderItemOption.ref} not found in catalog ${catalog?.id} for account ${catalog?.account_id} and location ${catalog?.location_id}`);
                }
                if (!name) {
                    // TODO: localize
                    name = "Supplément inconnu";
                }

                const optionDisplayItem: OrderDisplayItem = {
                    name: name,
                    type: OrderDisplayItemType.ORDER_OPTION,
                    price: orderItemOption.price ? MoneyToStringWithSymbol(orderItemOption.price) : undefined
                }
                optionNames.push(name);

                if (hierarchical) {
                    orderDisplayItem.subItems?.push()
                } else {
                    displayItems.push(optionDisplayItem);
                }
            })

            let customer_notes_text = "";
            if (orderItem.customer_notes) {
                customer_notes_text = L[selectedLocale].orders_comment({ comment: orderItem.customer_notes })
                const notesDisplayItem: OrderDisplayItem = {
                    name: customer_notes_text,
                    type: OrderDisplayItemType.ORDER_ITEM_NOTES,
                }
                if (hierarchical) {
                    orderDisplayItem.subItems?.push()
                } else {
                    displayItems.push(notesDisplayItem);
                }
            }
            if (optionNames.length) {
                orderDisplayItem.additional_info = this.getAdditionalInfoFromOptionsNames(optionNames);
            }
            if (orderItem.customer_notes) {
                orderDisplayItem.additional_info = orderDisplayItem.additional_info ? `${orderDisplayItem.additional_info} ${customer_notes_text}` : customer_notes_text;
            }
        }

        return displayItems;
    },

    getAdditionalInfoFromOptionsNames(optionsNamesList: string[]) {
        return `(${optionsNamesList.join(", ")})`
    },

    getOptionsNamesListFromAdditionalInfo(additionnalInfo: string) {
        const innerParenthese = additionnalInfo.slice(1, additionnalInfo.length - 1)
        return innerParenthese.split(",")
    },

    createOrderDisplayId(id: string): string {
        if (id) {
            return id.slice(0, Math.min(DISPLAY_ID_LENGTH, id.length)).toUpperCase();
        }
        return ""
    },

    /**
     * Find the first matching restriction given the order expected time
     */
    getFirstMatchingRestrictionForOrder(
        order: Order,
        timezone: string,
        restrictions: Restriction[]
    ): Restriction | undefined {

        const orderMoment = orderService.getOrderMoment(order, timezone);
        if (!orderMoment) {
            throw new Error(`Matching restriction can be found for order ${order.id}: null expected time and end preparation time`)
        }

        return getFirstMatchingRestriction(orderMoment, timezone, restrictions);
    },

    getOrderMoment(order: Pick<Order, "end_preparation_time" | "expected_time">, timezone: string): moment.Moment | null {
        const orderDate = order.end_preparation_time ?? order.expected_time;
        if (orderDate) {
            return moment(orderDate).tz(timezone);
        }
        return null;
    },

    /**
     * Check if the order should be counted into timeslot usage
     * E.g. A draft order should not count
     * @param order 
     * @returns 
     */
    isOrderCountedForTimeSlots(order: OrderInBase) {
        return order.status && order.status !== OrderStatus.DRAFT && order.status !== OrderStatus.CANCELLED
            && order.status !== OrderStatus.PENDING_PAYMENT && order.status !== OrderStatus.REJECTED_PAYMENT
            && order.status !== OrderStatus.WAITING_SUBMISSION;
        // TODO: check for time if pending payment
    },

    /**
     * Get all the timeslots available for a specific date (day)
     * @param allRestrictions 
     * @param date 
     * @returns the list of timeslots if restrictions exist for the given day; null if no restrictions matching
     */
    getOrderTimeslotsFillStatus(
        allRestrictions: Restriction[] | undefined | null,
        forCurrentTime: moment.Moment,
        timezone: string,
        orders: OrderInBase[],
        preparationTimeOverride: PreparationTimeOverride | undefined | null,
        deliveryDelay?: number
    ): OrderTimeSlot[] {

        forCurrentTime = forCurrentTime.tz(timezone);
        const forDate = moment(forCurrentTime).startOf("day");
        const dayNumber = getDayNumberFromMoment(forCurrentTime).toString();
        let allowedTimeSlots: OrderTimeSlot[] = [];
        let prepTimeOverride: number | undefined = undefined

        if (preparationTimeOverride) {
            if (preparationTimeOverride.override_to_date) {
                const overrideToDate = moment(preparationTimeOverride.override_to_date).tz(timezone);
                if (overrideToDate.isSameOrAfter(forCurrentTime)) {
                    prepTimeOverride = preparationTimeOverride.value
                }
            } else {
                prepTimeOverride = preparationTimeOverride.value
            }
        }

        if (allRestrictions) {

            if (allRestrictions.length === 0) {
                log.info("Empty restrictions detected, will return an empty list of timeslots");
                return [];
            }

            // Get the restrictions for today
            const res = allRestrictions?.filter(
                rest => (!rest.dow || rest.dow?.includes(dayNumber))
            );

            // If there is no restriction for the selected day, return null
            if (!res || !res.length) {
                return [];
            }
            else {
                res.forEach((restriction) => {
                    const timeSlots = getAllowedOrderTimeSlots(
                        forDate,
                        timezone,
                        forCurrentTime,
                        restriction.start_time,
                        restriction.end_time,
                        restriction.order_slot_time,
                        !_.isNil(prepTimeOverride) ? prepTimeOverride : restriction.min_preparation_time,
                        restriction.max_orders_per_slot
                    );

                    if (timeSlots.length) {
                        allowedTimeSlots = [...allowedTimeSlots, ...timeSlots];
                    }
                });
            }
        }
        // No restrictions, we return all the timeslots from 00:00 to 23:59
        else {
            allowedTimeSlots = getAllowedOrderTimeSlots(
                forDate,
                timezone,
                forCurrentTime,
                STARTING_HOUR,
                ENDING_HOUR,
                DEFAULT_COLLECTION_SLOT_MINUTES,
                !_.isNil(prepTimeOverride) ? prepTimeOverride : MIN_DEFAULT_PREPARATION_TIME
            );
        }

        orderService.fillTimeSlotsWithOrders(allowedTimeSlots, orders, timezone);

        // Now we add delivery delay
        deliveryHelper.addDeliveryDelayToTimeslots(allowedTimeSlots, deliveryDelay, timezone)

        // Sort timeslots by start date
        return allowedTimeSlots.sort((a, b) => {
            if (a.start_time > b.start_time) {
                return 1;
            } else if (a.start_time < b.start_time) {
                return - 1;
            } else {
                return 0;
            }
        })

    },

    /**
     * Fill the timeslots by associating each order with its matching timeslot
     * @param timeslots 
     * @param orders 
     */
    fillTimeSlotsWithOrders(timeslots: OrderTimeSlot[], orders: OrderInBase[], timezone: string): void {

        orders?.forEach((order) => {

            if (orderService.isOrderCountedForTimeSlots(order)) {

                const orderMoment: moment.Moment | null = orderService.getOrderMoment(order, timezone);

                if (orderMoment) {

                    const foundTimeSlot = timeslots.find(
                        (orderTimeSlot) => {

                            const startMomentWithoutSecs: moment.Moment = moment(orderTimeSlot.start_time).seconds(0).milliseconds(0);
                            const endMomentWithoutSecs: moment.Moment = moment(orderTimeSlot.end_time).seconds(0).milliseconds(0);

                            return (
                                startMomentWithoutSecs <= orderMoment
                                && orderMoment < endMomentWithoutSecs
                            );
                        }
                    );

                    if (foundTimeSlot) {

                        if (!foundTimeSlot.current_orders_count) {

                            foundTimeSlot.current_orders_count = 1;
                        }
                        else {

                            foundTimeSlot.current_orders_count++;
                        }

                        if (foundTimeSlot.max_orders_count && foundTimeSlot.current_orders_count >= foundTimeSlot.max_orders_count) {
                            foundTimeSlot.full = true;
                        }
                    }
                }
            }
        })
    },

    /**
     * Check if an identical item (same options, not part of a deal, not already sent to the POS) already exists in the order
     * Used to increase quantity instead of creating new item
     * TODO: to be tested
     * @param order 
     * @param item 
     */
    getSameItemIndex(order: Order, item: { sku_ref: string; options: OrderOption[] }): number {
        const index = order.items.findIndex(
            (elem) =>
                elem.sku_ref === item.sku_ref && !elem.update_id &&
                !elem.deal_line &&
                orderService.optionEquals(item.options, elem.options),
        );

        return index;
    },

    /**
     * Say if item1 and item2 are the same items, meaning they have the same sku_refs,
     * not being in a deal, and have the same options
     * @param item1 
     * @param item2 
     * @returns 
     */
    areTwoItemsIdenticals(item1: OrderItem, item2: OrderItem, checkQuantityAndPrice?: boolean): boolean {

        if (
            item1.sku_ref === item2.sku_ref
            && !item1.deal_line
            && !item2.deal_line
            && orderService.optionEquals(item2.options, item1.options)
        ) {
            if (checkQuantityAndPrice) {
                return item1.quantity === item2.quantity && item1.price === item2.price && item1.subtotal === item2.subtotal;
            } else {
                return true;
            }
        }

        return false;
    },

    /**
     * Compare two list of options to check if the ordered products are the same
     * @param t1 
     * @param t2 
     */
    optionEquals(t1: OrderOption[], t2: OrderOption[]): boolean {
        if (t1?.length !== t2?.length) {
            return false;
        }
        const sortOrderOptions = (a: OrderOption, b: OrderOption): number => {
            if (a.ref > b.ref) {
                return 1;
            } else if (a.ref === b.ref) {
                return 0;
            } else {
                return -1;
            }
        }
        let sorted = t1.sort(sortOrderOptions);
        return t2
            .sort(sortOrderOptions)
            .every(
                (value: any, index: any) =>
                    sorted[index] &&
                    sorted[index].ref === value.ref &&
                    sorted[index].option_list_ref === value.option_list_ref,
            );
    },

    /**
     * Know if an order needs to go trough the pricing process based
     * mainly on its status. For example, to display a paid order we don't
     * have to process the price again.
     * @param order 
     */
    orderNeedsPricing(order: Order): boolean {

        return (order.status === OrderStatus.DRAFT || order.status === OrderStatus.WAITING_SUBMISSION);
    },

    /**
     * Check in the order contributors if the user is in it.
     * @param user 
     * @param order 
     * @returns 
     */
    checkIfUserIsAContributor(order: Order, user_uid: string): boolean {

        if (order.contributors) {

            // Searching for the user uid in the contributors list
            for (const uid of Object.keys(order.contributors)) {

                if (uid === user_uid) {

                    return true;
                }
            }
        }

        return false;
    },

    replaceUserInOrder(order: Order, oldUserId: string, newCustomer: Customer) {

        if (newCustomer.uid) {

            if (!newCustomer.old_anonymous_ids) {
                newCustomer.old_anonymous_ids = [];
            }
            newCustomer.old_anonymous_ids.push(oldUserId);

            if (order.user_id === oldUserId) {
                order.user_id = newCustomer.uid;
            }
            if (order.master_user_uid === oldUserId) {
                order.master_user_uid = newCustomer.uid;
            }
            if (order.contributors && order.contributors[oldUserId]) {
                order.contributors[newCustomer.uid] = {
                    uid: newCustomer.uid,
                    sign_in_provider: newCustomer.sign_in_provider,
                    email: newCustomer.email,
                    email_verified: newCustomer.email_verified,
                    loyalty_balance: newCustomer.loyalty_balance,
                    use_points: order.contributors[oldUserId].use_points,
                    used_deals: newCustomer.used_deals,
                    used_discounts: newCustomer.used_discounts,
                    old_anonymous_ids: newCustomer.old_anonymous_ids,
                }
                delete order.contributors[oldUserId];
            }
            if (order.customer && order.customer.uid === oldUserId) {
                //Replace customer information except delivery address
                const oldCustomer = order.customer;

                // If name, email or phone are empty, keep the old ones
                if (!newCustomer.first_name && oldCustomer.first_name) {
                    newCustomer.first_name = oldCustomer.first_name;
                }
                if (!newCustomer.last_name && oldCustomer.last_name) {
                    newCustomer.last_name = oldCustomer.last_name;
                }
                if (!newCustomer.phone && oldCustomer.phone) {
                    newCustomer.phone = oldCustomer.phone;
                }

                order.customer = {
                    ...newCustomer,
                };

                if (order.service_type === SupportedServiceType.DELIVERY) {
                    // Keep user already selected address
                    order.customer.delivery_address_type_choice = oldCustomer.delivery_address_type_choice;
                    order.customer.delivery_notes = oldCustomer.delivery_notes;
                    order.customer.address_1 = oldCustomer.address_1;
                    order.customer.address_2 = oldCustomer.address_2;
                    order.customer.city = oldCustomer.city;
                    order.customer.postal_code = oldCustomer.postal_code;
                    order.customer.country = oldCustomer.country;
                    order.customer.latitude = oldCustomer.latitude;
                    order.customer.longitude = oldCustomer.longitude;
                    removeUndefinedFromObject(order.customer);
                }
            }
        }
    },

    /**
     * Take an order and remove items which are cancelled after. For example
     * if there is a beer with quantity 3 and that the same beer is found later
     * in the array with quantity -3, the 2 items will be removed.
     * @param order 
     */
    removeCancelledItemsFromOrder(order: Order): void {

        // Using a Set instead of an array for values unicity
        const indexesToRemove: Set<number> = new Set<number>();

        // Iterating trough the items with index1
        order.items.forEach((item1, index1) => {

            const indexesToSum: Set<number> = new Set<number>();

            // Iterating trough the items with index2 (only those above index1)
            order.items.forEach((item2, index2) => {

                if (index2 <= index1) {
                    return;
                }

                if (this.areTwoItemsIdenticals(item1, item2)) {

                    indexesToSum.add(index1);
                    indexesToSum.add(index2);
                }
            });

            if (indexesToSum.size > 1) {

                // Finished browsing the array: let's make a sum to see if we remove the items or not
                let sum: number = 0;
                indexesToSum.forEach((indexToSum) => {

                    sum += order.items[indexToSum].quantity;
                });

                // We know there's at least two items to check for this sum, so this 0 can't be the default value
                // If it's 0, we add all the indexes of the sum in the list for deletion.
                if (sum === 0) {

                    indexesToSum.forEach((indexToSum) => {

                        indexesToRemove.add(indexToSum);
                    });
                }
            }
        });

        // At least 2 elements to remove
        if (indexesToRemove.size > 1) {

            const newItems = order.items.filter((_, index) => !indexesToRemove.has(index));
            order.items = newItems;
        }
    },

    replaceUpdateId(order: Pick<Order, "update_id" | "update_id_history">, updateId: string): void {
        if (order.update_id) {
            // Old update id is supposed to be already in history but to be sure we save it again
            this.addUpdateIdToHistory(order, order.update_id);
        }
        this.addUpdateIdToHistory(order, updateId);
        order.update_id = updateId;
    },

    addUpdateIdToHistory(order: Pick<Order, "update_id" | "update_id_history">, updateId: string): void {
        if (updateId) {
            if (!order.update_id_history) {
                order.update_id_history = [];
            }
            if (!order.update_id_history.includes(updateId)) {
                order.update_id_history.push(updateId);
            }
        }
    },

    /**
     * For each entity of the arrays given in parameters, set the
     * uptate_id if not done yet.
     * @param updateId 
     * @param items 
     * @param discounts 
     * @param charges 
     * @param payments 
     */
    fillUpdateIds(
        updateId: string,
        items: OrderItem[] | undefined,
        discounts: OrderDiscount[] | undefined,
        charges: OrderCharge[] | undefined,
        payments: OrderPayment[] | undefined,
    ): void {

        const updateCount = {
            items: 0,
            discounts: 0,
            charges: 0,
            payments: 0,
        }

        items?.forEach((item) => {
            if (!item.update_id) {
                item.update_id = updateId;
                updateCount.items++;
            }
        });

        discounts?.forEach((discount) => {
            if (!discount.update_id) {
                discount.update_id = updateId;
                updateCount.discounts++;
            }
        });

        charges?.forEach((charge) => {
            if (!charge.updated_id) {
                charge.updated_id = updateId;
                updateCount.charges++;
            }
        });

        payments?.forEach((payment) => {
            if (!payment.update_id) {
                payment.update_id = updateId;
                updateCount.payments++;
            }
        });

        log.info("Added update_ids to order entities", updateCount);
    },

    validateOrUpdateTimeslot(
        orderTimeSlot: OrderTimeSlot,
        order: Order,
        runningOrders: OrderInBase[],
        location: Location,
        endPreparationTime: moment.Moment,
        matchingRestriction: Restriction,
        now: moment.Moment,
    ) {
        let newOrderTimeSlot = orderTimeSlot

        // This function is use to know if the current timeslot is full or not with the running orders
        this.fillTimeSlotsWithOrders([orderTimeSlot], runningOrders, getTimezoneName(location));
        log.info(`Order timeslot current status: ${JSON.stringify(orderTimeSlot)}`)

        /**
         * Removing the preparation time to simulate as if we were placing the order just before its preparation.
         * Needed for the getOrderTimeslotsFillStatus function which returns all the next available timeslots
         */
        let orderMomentWithoutPreparationTime = _.cloneDeep(endPreparationTime)
        orderMomentWithoutPreparationTime.subtract(matchingRestriction.min_preparation_time
            ? matchingRestriction.min_preparation_time
            : MIN_DEFAULT_PREPARATION_TIME, "minutes"
        );

        /**
         * We wanted to simulate as if we placed the order just before its preparation. But if we are already
         * after this simulated time, we cannot keep it. We need to use the current time.
         * Ex: order placed at 15:42, preparation time = 5min, expected end preparation time (from the webapp) = 15:45
         * We cannot let the user place the order at 15:42 and expect it to be ready at 15:45. We need to use the current time to
         * take the next real available slot: 15:50.
         */
        if (now > orderMomentWithoutPreparationTime) {
            log.info(`orderMomentWithoutPreparationTime is in the past, slot will probably be too soon, using now instead`, {
                endPreparationTime: endPreparationTime.toString(),
                orderMomentWithoutPreparationTime: orderMomentWithoutPreparationTime.toString(),
                now: now.toString(),
            });
            orderMomentWithoutPreparationTime = now;
        }

        const timeSlotNextOrder = orderService.getOrderTimeslotsFillStatus(
            [matchingRestriction],
            orderMomentWithoutPreparationTime,
            getTimezoneName(location),
            runningOrders,
            location.orders?.preparation_time_override,
            order.delivery?.delivery_delay
        )

        const firstTimeSlotNotFull = timeSlotNextOrder.find(timeSlot => !timeSlot.full)

        if (firstTimeSlotNotFull) {
            log.info(`In this case, ${JSON.stringify(firstTimeSlotNotFull)} is the next time slot not full`)
        }

        if (
            orderTimeSlot
            && !orderTimeSlot.full
            && firstTimeSlotNotFull
            && orderTimeSlot.start_time >= firstTimeSlotNotFull?.start_time
        ) {
            log.info(`Time slot not full order expected time is valid`, {
                orderTimeSlot,
                firstTimeSlotNotFull,
            })
        } else {
            log.info(`Time slot is full or order expected time is not valid anymore, need to update order expected time`, {
                orderTimeSlot,
                firstTimeSlotNotFull,
            });
            if (!orderTimeSlot) {
                log.error(`Couldn't retrieve orderTimeSlot with service getOrderTimeslotsFillStatus`);
                throw OrderError.TOO_MANY_ORDERS_IN_SLOT.withValue({
                    message: "Couldn't retrieve orderTimeSlot with service getOrderTimeslotsFillStatus",
                });
            }
            else {
                if (firstTimeSlotNotFull) {

                    log.info(`Order timeslot is full or order expected time is not valid anymore`, {
                        orderTimeSlot,
                    });
                    if (order.expected_time_asap) {

                        log.info(`Order is asked ASAP, changing expected time to ${firstTimeSlotNotFull.start_time}`, {
                            orderTimeSlot,
                            firstTimeSlotNotFull,
                        });
                        const firstTimeSlotNotFullStart = DateTime.fromJSDate(getValidDate(firstTimeSlotNotFull.start_time)).setZone(getTimezoneName(location));

                        // check computeEndPreparationTime for the whole logic, we already have the parameters to do it raw here
                        order.end_preparation_time = firstTimeSlotNotFullStart.minus({ [DEFAULT_DELIVERY_TIME_UNIT]: order.delivery?.delivery_delay ?? 0 }).toJSDate();

                        order.expected_time = firstTimeSlotNotFullStart.toJSDate();
                        newOrderTimeSlot = firstTimeSlotNotFull;
                    }
                    else {
                        log.error("Order is not asked ASAP, cannot change expected time without user confirmation")
                        throw OrderError.TOO_MANY_ORDERS_IN_SLOT.withValue({
                            time_slot: orderTimeSlot
                        });
                    }
                }
                else {
                    log.error(`Order timeslot is full or expected time is not valid anymore & no other slot was found`, {
                        orderTimeSlot,
                    });
                    throw OrderError.TOO_MANY_ORDERS_IN_SLOT.withValue({
                        message: `Order timeslot is full or expected time is not valid anymore & no other slot was found`,
                        orderTimeSlot,
                    })
                }
            }
        }
        log.info(`Found timeslot for order time ${endPreparationTime}`, newOrderTimeSlot);

        return newOrderTimeSlot
    }
}

/**
 * Returns the payment type of an order or "multiple" if there are multiple
 * @param order 
 * @param intl 
 * @returns 
 */
export const getDisplayedPaymentType = (order: Pick<Order, "payments">): PaymentType | "multiple" | null => {

    const encounteredPaymentTypes: PaymentType[] = [];
    order.payments?.forEach((payment) => {
        if (!encounteredPaymentTypes.includes(payment.payment_type)) {
            encounteredPaymentTypes.push(payment.payment_type);
        }
    });

    let paymentType: PaymentType | "multiple" | null = null;
    if (encounteredPaymentTypes.length === 1) {
        paymentType = encounteredPaymentTypes[0];
    } else if (encounteredPaymentTypes.length > 1) {
        paymentType = "multiple";
    }

    return paymentType;
};
