import type { Translation } from '../i18n-types'

const en: Translation = {
    order: 'Order',
    orders_total: 'Total',
    orders_comment: 'Comment: {comment}',
    orders_display_info_no_deal: 'Not in menu',
    invoices_fee_created_at_label: "Created at",
    invoice_fee_status_label: "Status",
    invoice_fee_seller_amount_label: "Seller amount",
    invoice_fee_transaction_amount_label: "Transaction amout",
    invoice_fee_order_number_label: "Order number",
    invoice_fee_intent_id_label: "Payment number",
    invoice_fee_fee_amount_label: "Fee amount",
    invoice_fee_currency_label: "Currency",
    invoice_fee_payment_method_label: "Payment method",
    invoices_title: 'Invoice',
    invoices_reference_label: "Reference: {invoice_reference}",
    invoices_date_label: "Order Date: {order_date} {order_hour}",
    invoices_generated_label: "Generated: {datetime}",
    invoices_contact_label: 'Customer: {contact_name}',
    invoices_contact_email_label: "Email: {contact_email}",
    invoices_page_label: "Page ",
    invoices_total_header_title: "Total",
    invoices_total_label: "Total",
    invoices_subtotal_label: "Subtotal",
    invoices_quantity_header_title: "Quantity",
    invoices_item_header_title: "Item",
    invoices_registration_number: "Registration number: {registration_number}",
    invoices_tax_number: "VAT: {tax_number}",
    invoices_tax_rate: "VAT {tax_rate}%",
    invoices_note_payment: 'Payment: {date} {time}',
    invoices_note_latest_payment: 'Latest payment: {date} {time}',
    invoices_note_meal: "Meal",
    invoices_note_payment_id: "Payment #{number} ID",
    invoices_note_title: 'Note',
    invoices_note_tips: 'Including tip',
    invoices_note_total: 'Total paid',
    invoices_order_title_header: 'Invoice generated by Get Resto for the account of:',
    invoices_marketplace_fees_label: 'Payment fees for the period from \n{start} to ${end}',
    invoices_marketplace_generated_for: 'Invoice generated for:',
    order_charge_service_fee: "Service fee",
    order_charge_tip: "Tip",
    order_discount: "Discount",
    order_charge_delivery: "Delivery fee",
    printers_order_receipt_title: "Order receipt",
    printers_payment_receipt_title: "Payment receipt",
    printers_payment_receipt_table: "Table {table}",
    printers_payment_receipt_payment_date: "{date} at {time}",
    printers_payment_receipt_paid_amount: "AMOUNT = {amount}",
    printers_payment_receipt_tip_amount: "tip part = {amount}",
    printers_payment_receipt_remaining_amount: "REMAINING AMOUNT = {amount}",
    printers_payment_receipt_qrcode_title: "Pay by app",
    printers_payment_receipt_qrcode_subtitle: "Scan & earn loyalty points",
    printers_orders_summary_title: "Orders summary",
    printers_orders_summary_dates_title: "Dates",
    printers_orders_summary_dates_from: "From {date}",
    printers_orders_summary_dates_to: "To {date}",
    printers_orders_summary_service_types: "Services",
    printers_orders_summary_service_types_view: "View",
    printers_orders_summary_service_types_eat_in: "Eat-in",
    printers_orders_summary_service_types_collection: "Collection",
    printers_orders_summary_service_types_delivery: "Delivery",
    printers_orders_summary_service_types_checkout: "Checkout",
    printers_orders_summary_total_title: "Total",
    printers_orders_summary_total_amount: "Ordered : {amount}",
    printers_orders_summary_total_tip: "Tips : {amount}",
    printers_orders_summary_details_title: "Details",
    printers_orders_summary_details_date: "Time",
    printers_orders_summary_details_table: "Table",
    printers_orders_summary_details_amount: "total",
    printers_orders_summary_details_tip: "Tips",
    printers_receipt_order_default_item: "Order {amount}",
    printers_receipt_order_adjustment_item: "Other",
    discounts_default_name: "Discount",
    discounts_description: "{value} on {{number:all your next|your next|your ?? next}} {{number:orders|order|orders}} {service_types}",
    discounts_service_type_view: "view",
    discounts_service_type_eat_in: "eat-in",
    discounts_service_type_collection: "pickup",
    discounts_service_type_delivery: "delivery",
    discounts_service_type_checkout: "payment",
    discounts_service_type_or_join: " or ",
    discount_name_free: "Free: {name}",
    discount_name_default: "{discount_value} discount on {name}",
    discount_name_global: "{discount_value} on global",
    printers_receipt_service_type_eat_in: "Eat-in",
    printers_receipt_service_type_collection: "Pickup",
    printers_receipt_service_type_delivery: "Delivery",
    printers_receipt_service_type_checkout: "Payment",
    location_company_info_not_specified: "Not specified",
    catalog_merge_default_new_category_name: "New",
    printers_receipt_payment_status_paid: "Paid",
    printers_receipt_payment_status_pending: "Pending",
    printers_receipt_payment_status_refunded: "Refunded",
    printers_receipt_payment_status_unpaid: "Unpaid",
    printers_receipt_payment_status_failed: "Failed",
    printers_receipt_payment_status_partially_paid: "Partially paid",
    printers_receipt_delivery_manager_phone_number: "Manager: {manager_phone_number}",
    printers_receipt_payment_end_prep_time: "End preparation at: {time}",
    charges_category_title: "charges",
    charge_delivery: "Delivery",
    charge_service_fee: "Service",
    charge_tip: "Tip",
}

export default en
