import NotificationDic from '../notifications/models/NotificationDic';
import { NotificationServiceType } from '../notifications/models/NotificationServiceType';
import LocationAnalyticsConfig from '../src/analytics/models/LocationAnalyticsConfig';
import LocationHotjarConfig from '../src/analytics/models/LocationHotjarConfig';
import { SignInProviders } from '../src/authentications/models/BaseUser';
import { EnvNames } from '../src/common/configs/EnvNames';
import { DeploymentName } from '../src/common/models/DeploymentInfo';
import { Money } from '../src/common/models/Money';
import Position from '../src/common/models/Position';
import { log } from '../src/common/services/LogService';
import { Connector } from '../src/connectors/models/Connector';
import DeliveryZone from '../src/delivery/models/DeliveryZone';
import LocationDeliveryProvider from '../src/delivery/models/LocationDeliveryProvider';
import DiscountConfig from '../src/discounts/models/DiscountConfig';
import LocationAnnualSalesRange from '../src/locations/models/LocationAnnualSalesRange';
import LocationAverageCheck from '../src/locations/models/LocationAverageCheck';
import LocationLink from '../src/locations/models/LocationLink';
import { LocationMenuType } from '../src/locations/models/LocationMenuType';
import LocationOwnerType from '../src/locations/models/LocationOwnerType';
import { LocationUniverseType } from '../src/locations/models/LocationUniverseType';
import LocationYearsInBusiness from '../src/locations/models/LocationYearsInBusiness';
import { MarketSegmentTypeLevels } from '../src/locations/models/MarketSegmentTypeLevels';
import { LoyaltyLocationConfig } from '../src/loyalties/models/LoyaltyLocationConfig';
import LocationOrdersConfig from '../src/orders/models/LocationOrdersConfig';
import PaymentFeeConfig from '../src/payments/models/PaymentFeeConfig';
import QrCodeConfiguration from '../src/qrcodes/models/QrCodeConfiguration';
import { DeliveryRestrictions } from '../src/restrictions/model/DeliveryRestrictions';
import { Restriction } from '../src/restrictions/model/Restriction';
import { LocationSessionsConfig } from '../src/sessions/models/LocationSessionsConfig';
import { StatisticsDashboardLayoutConfig } from '../src/stats/model/StatisticsDashboardLayoutConfig';
import TableArea from '../src/tables/models/TableArea';
import { Tax } from '../src/taxes/models/Tax';
import { LocationTheme } from '../src/theme/models/LocationTheme';
import { DEFAULT_TIMEZONE_NAME, Timezone } from './Catalog';
import { LogLevels } from './Common/Log';
import { PaymentType } from './Order';

export const DEFAULT_COUNTRY = 'FR';
export const DEFAULT_DEPLOYMENT_NAME = DeploymentName.ALL_EAT_ONE;

export interface Location {
    name: string;
    from_template_id?: string;
    /**
     * Dynamic creation when a first order has been received
     */
    order_webhook_creation?: boolean;
    supported_service_types: SupportedServiceType[]; //first one is selected
    supported_payment_types: SupportedPayementType[];
    currency: string;
    id: string;
    anonymous_id?: string;
    anonymous_name?: string;

    anonymous_cell_id?: string;

    account_id: string;
    delivery_providers?: LocationDeliveryProvider[];
    delivery?: DeliveryZone[];
    delivery_tax?: Tax;
    /**
     * Instruction to indicate restaurant position for courriers
     */
    delivery_notes?: string;
    /**
     * Reference in the connector
     */
    ref?: string;

    /**
     * Reference in other systems
     */
    external_refs?: { [externalSystemId: string]: string };
    city?: string;

    /**
     * @deprecated: use postal_code instead
     */
    zipcode?: string;
    address?: string;
    /**
     * ISO Country code (capital letters)
     */
    country?: string;
    language?: string;
    postal_code?: string;
    email?: string;
    registration_number?: string;
    tax_number?: string;
    phone?: string;
    website_url?: string;
    connector?: Connector | null;
    pin_enabled?: boolean;
    background_image?: string;
    customer_phone_countries?: string[];
    require_customer_info?: RequireCustomerInfo;
    logo_header?: string;
    logo?: string;
    links?: LocationLink[];
    deployment?: DeploymentName;
    /**
     * Used for favicon for example
     */
    icon?: string;
    /**
     * @deprecated: use disabled_service_types for now
     */
    disable?: boolean;
    disabled_service_types?: SupportedServiceType[];
    information_banner?: string;
    description?: string;
    notifications_provider?: NotificationServiceType;
    notifications?: NotificationDic;
    default_table_link?: string;
    restriction?: Restriction[] | null; // TODO: rename to restrictions
    timezone: Timezone;
    position?: Position;

    anonymous_position?: Position;

    custom_fields?: Position; //DEPRECATED, use position
    /**
     * field filled with a date of deletion, fully deleted after a delay
     */
    deleted_at?: Date;

    /**
     * Configuration used to generate a qr code for the order
     * TODO: to be moved in LocationOrdersConfig
     */
    order_qr_code?: QrCodeConfiguration;

    /**
     * Config for printers, alarms, qrcode, invoices
     */
    orders?: LocationOrdersConfig;

    /**
     * If true, the qrcode api link will redirect directly to payment link / order link if defined
     * without loading first our webapp
     */
    qrcode_redirect_external_link?: boolean;

    discounts?: DiscountConfig;

    updated_at?: Date;

    /**
     * User if for the user responsible for the update
     */
    updated_by?: string;

    /**
     * ip address for the call responsible for the update
     */
    updated_with_ip?: string;

    display_ios_scan_popup?: boolean;

    theme?: LocationTheme;

    loyalty?: LoyaltyLocationConfig;

    /**
     * Service fee configs (one for each service type and/or one for every service type)
     */
    service_fees?: PaymentFeeConfig[];

    hotjar?: LocationHotjarConfig;

    analytics?: LocationAnalyticsConfig;

    /**
     * @deprecated using terms as defined in the corresponding collection
     */
    terms_and_conditions_url?: string;

    privacy_policy_url?: string;

    // Enable the "share payment" feature. WARN: some other conditions must be met for the page to appear in the
    // webapp, such as the order amount or the number of products in cart. See "shouldRedirectToSharePayment" function in webapp
    enable_share_payment?: boolean;

    /**
     * Used to configure additional features for the share payment
     */
    share_payment_config?: SharePaymentConfig;

    display_unavailable_product?: boolean;

    table_areas?: TableArea[];

    /**
     * Copied to the catalogs to ease some operations but master is supposed to be here
     */
    taxes?: Tax[];

    sessions?: LocationSessionsConfig;

    statistics_dashboard_layout_config?: StatisticsDashboardLayoutConfig;

    unused_by_restaurant_owner?: boolean;

    /**
     * If true, the location is browsable in the brand platform
     */
    brand_browsable?: boolean;

    with_menu?: boolean; // true if the location has a menu

    tracked_sales?: boolean; // true if the location has a tracked sales

    market_segment_type?: MarketSegmentTypeLevels;

    universe_type?: LocationUniverseType;

    menu_type?: LocationMenuType;

    owner_type?: LocationOwnerType;

    /**
     * Name of the chain for lcoations with owenrt_type=chain
     */
    chain_name?: string

    /**
     * A geographical area bigger than administrative_area0
     */
    zone?: string;

    /**
     * Code of the region in france
     */
    administrative_area0?: string;

    /**
     * Name of the region in france
     */
    administrative_area0_name?: string;

    /**
     * Code of the department in france
     */
    administrative_area1?: string;

    /**
     * Name of the department in france
     */
    administrative_area1_name?: string;

    annual_revenue?: number;

    annual_sales_range?: LocationAnnualSalesRange;

    years_in_business?: LocationYearsInBusiness;

    average_check?: LocationAverageCheck;

    estimated_adult_beverage_purchase?: number;

    estimated_beverage_purchase?: number;

    estimated_food_purchase?: number;

    estimated_non_alcoholic_beverage_purchase?: number;

    // fields used to set the legal entity of the company
    // and to create an admin contact
    company?: LocationCompany;

    /**
     * Disable generation of location statistics
     */
    disable_statistics?: boolean;

    /**
     * Is this location used for representing the market panel
     */
    brand_market_panel?: boolean;

    /*
     * Allow to browse the menu in checkout mode. Will show a button at the
     * step where the user wants to pay their ticket.
     * Can be disabled table by table in their restrictions.
     */
    allow_to_browse_catalog_for_checkout_service_type?: boolean;

    /**
     * Refers to the total seating capacity of the restaurant
     */
    seating_capacity?: number;
}

export function getTimezoneName(location?: Pick<Location, 'timezone'>): string {
    if (location && location.timezone && location.timezone.name) {
        return location.timezone.name;
    } else {
        if (location) {
            log.warn(
                `No timezone defined for location, using default timezone: ${DEFAULT_TIMEZONE_NAME}`
            );
        }
        return DEFAULT_TIMEZONE_NAME;
    }
}

export enum SupportedServiceType {
    VIEW = 'view',
    EAT_IN = 'eat_in',
    COLLECTION = 'collection',
    DELIVERY = 'delivery',
    /**
     * Checkout = do not browse catalog and send orders, only pay existing orders
     */
    CHECKOUT = 'checkout',
}

export enum PaymentMethodType {
    CB = 'cb',
    UP = 'up',
    NATIXIS = 'natixis', // now BIMPLY
    SODEXO = 'sodexo',
    EDENRED = 'edenred',
    SWILE = 'swile',
}

/**
 * Base interface to describe a payment type and its properties. Can be
 * extended with more information depending on the payment type. Ex: LyraMarketplace
 */
export interface SupportedPayementType {
    type: PaymentType;

    /**
     * If true, the payment is not automatically refunded/cancelled when an error occurs
     * Usefull, if the restaurant wants to keep the payment and manually add it in the pos for instance
     */
    disable_error_auto_refund?: boolean;

    min_amount?: Money;
    max_amount?: Money;
    ref: string;
    method_types?: PaymentMethodType[];

    /**
     * Location seller Id in the payment system
     */
    seller_id?: string | null;

    /**
     * If type=SHARE, we have to specify here which one (lyra, stripe, ..) will
     * be used. The user does not have the choice. We put the ref to the main
     * payment type
     */
    share_provider_type?: PaymentType;

    /**
     * Payment provider environment
     */
    force_environment?: EnvNames;

    /**
     * ISO Country code (capital letters)
     * Used by Edenred at the moment: different logics depending on the country.
     */
    country?: string;

    /**
     * Use to configure the wallet configuration type (e.g: you can choose between "steps" or "percentage" to have some advantages when the user credit the wallet.)
     */
    connector_wallet_configuration?: ConnectorWalletConfiguration;
}

export interface Table {
    id: string;
    name: string;
    ref: string;
    area_ref?: string;
    tablelink_id?: string;
    disable?: boolean;
    location_id?: string;
    account_id?: string;
    service_type?: SupportedServiceType;
    restrictions?: Restriction | null;

    /**
     * Number of covers for this table
     */
    covers?: number;

    /**
     * Refs of product categories which must be ignored when displaying the webapp associated to this table
     */
    disabled_categories?: {
        catalog_id: string;
        refs: string[];
    };

    /**
     * Links for this table
     * By default, location links are used if null.
     * But can be used as override to disable some links, add new ones, override others
     */
    links?: LocationLink[];

    delivery_restrictions?: DeliveryRestrictions;

    /**
     * @link Location.allow_to_browse_catalog_for_checkout_service_type
     * If true, disables the behaviour of allowing to show the menu in checkout mode.
     */
    do_not_allow_to_browse_catalog_for_checkout_service_type?: boolean;
}

export interface TableLink {
    id: string;

    account_id: string;
    location_id: string;

    created_at?: Date;

    /**
     * Reference. Used to share a same identifier for NFC & QRCode links
     */
    ref?: string;

    // OPTIONAL FIELDS
    // It is now possible to create a tablelink without assignating yet a table and catalog
    table_id?: string;
    catalog_id?: string;

    /**
     * More informative if filled
     */
    table_ref?: string;
    table_name?: string;

    /**
     * @deprecated - URLs only make sense when printed, or displayed in the BO
     * The generation MUST be live and user must choose between deployments and environments.
     * Example: the same tablelink in preprod and prod can "have" different URLs depending on where you
     * want to access it.
     */
    url?: string;

    /**
     * Used to force the log level for a tablelink
     */
    log_level?: LogLevels;
}

export type RequireCustomerInfo = {
    [serviceType in SupportedServiceType]?: CustomerInfo;
};

export interface CustomerInfo
    extends OrderInfosRequire,
    CustomerManagementProcess { }

export interface OrderInfosRequire {
    expected_time: boolean;
    first_name: boolean;
    phone: boolean;
    email: boolean;
    table?: boolean;
    customer_notes?: boolean;
    company_name?: boolean;

    /**
     * Can the customer leave a review
     */
    review?: boolean;
}

export interface CustomerManagementProcess {
    disable?: boolean;
    authentication_mandatory?: boolean;
    authentication_process?: CustomerInfoFlowChoice;
    authentication_providers?: SignInProviders[];
    email_domains_allowed?: string[];
    customer_email_verified?: boolean;
    table_flow?: CustomerInfoFlowChoice;
    table_label?: TableLabel; // Table by default
    /**
     * When the expected time will be asked
     */
    expected_time_flow?: CustomerInfoFlowChoice;
}

export enum TableLabel {
    TABLE = 'table',
    BEDROOM = 'bedroom',
    BUNGALOW = 'bungalow',
}

/**
 * When the customer info will be collected in the order process.
 * BOTH by default
 */
export enum CustomerInfoFlowChoice {
    START = 'start',
    END = 'end',
    BOTH = 'both',
    NONE = 'none',
}

export interface LocationCompany {
    legal_entity: LegalEntity;
    admin_contact: AdminContact;
}

export interface LegalEntity {
    name: string;
    address: string;
    tax_number: string;
    registration_number: string;
}

export interface AdminContact {
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
}

export interface SharePaymentConfig {
    item_selection_only?: boolean;
}

export interface ConnectorWalletConfiguration {
    type: WalletConfigurationType;
    configuration: WalletStepConfiguration[] | WalletPercentageConfiguration;
}

export enum WalletConfigurationType {
    STEPS = 'steps',
    PERCENTAGE = 'percentage',
}
export interface WalletStepConfiguration {
    amount_to_reach: Money;
    credited_amount: Money;
}
export interface WalletPercentageConfiguration {
    // number between 0 and 1
    percentage: number;
}
