import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Alert, AlertTitle } from '@mui/lab';
import { Box, Dialog, IconButton, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import 'firebase/analytics';
import firebase from 'firebase/app';
import log from 'loglevel';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import { DeviceContext } from '../../App';
import DefaultBackground from '../../assets/bg_home.png';
import { isEntityOrderableNow } from '../../catalog/services/CatalogService';
import DisplayHeader from '../../Common/components/DisplayHeader';
import { MEDIUM_DIALOG_WIDTH, SMALL_DIALOG_WIDTH } from '../../Common/configs/DialogSizeConst';
import useShowAlcoholDisclaimer from '../../Common/hooks/useShowAlcoholDisclaimer';
import { AddButtonRedirect } from '../../Common/models/AddButtonRedirect';
import { mobilePaperProps } from '../../Common/StyledComponents';
import * as ROUTES from '../../config/routes';
import { Categorie, OptionList, Product, Sku } from '../../my-lemonade-library/model/Catalog';
import { OrderItem, OrderOption } from '../../my-lemonade-library/model/Order';
import { ProductExtended, SkuExtended } from '../../my-lemonade-library/model/ProductExtended/ProductExtended';
import AnalyticsAddToCartEvent from '../../my-lemonade-library/src/analytics/models/AnalyticsAddToCartEvent';
import analyticsHelper from '../../my-lemonade-library/src/analytics/services/AnalyticsHelper';
import { addMoney, Money, MoneyToStringWithSymbol, multiplyMoney, numberToMoney } from '../../my-lemonade-library/src/common/models/Money';
import { consolidateOptionsInfo } from '../../my-lemonade-library/src/orders/services/OrderPricing';
import productService from '../../my-lemonade-library/src/products/services/ProductService';
import { default as OrderAction } from '../../orders/redux/OrderActions';
import { RootState, useTypedSelector } from '../../redux/root-reducer';
import AddProductButton from '../component/AddProductButton';
import AlcoholDisclaimerModal from '../component/AlcoholDisclaimerModal';
import ProductOptions from '../component/ProductOptions';

interface ProductModalProps {

    open: boolean,
    closeModal: () => void,

    selectedProduct: ProductExtended,  // Here we manipulate ProductExtended but it does not matter if we pass a Product because all the extended values are optional
    initialOptions?: OrderOption[];

    hideQuantityButton?: boolean;

    // If defined, this function will be responsible for adding the product to the order. In fact, we use it to add a product to a deal.
    onAddProduct?: (selectedProduct: ProductExtended, selectedOptions: OrderOption[], selectedSku: SkuExtended, quantity: number) => void,
    dealSku?: SkuExtended;  // If defined, the product modal is called when making a deal

    buttonTitleId?: string,
    quantityPreSelected?: number,
    note?: string,  // customer note for this product
    setNote?: (note: string) => void,

    hidePrice?: boolean;
    hideReorder?: boolean;

    backToMenu?: boolean // If true, the backToName as location's name, no longer the category's name
    forceImageDisplay?: boolean // If true, display product or catalog image even if user is not using mobile device
    forceDescriptionDisplay?: boolean // If true, display product or catalog image even if user is not using mobile device
    forcedBackToLabel?: string  // If defined, the backToName will be this value instead of the category's name (used for deals)
}

/**
 * Shows the product, its image, its descripton, the options and finally the quantity and add button.
 * Is used on mobile for all products, but also on desktop when adding a complex product to a deal.
 * //TODO: use this component for desktop version, prepare two cssProperties dynamique with responsive
 */
const ProductModal: React.FC<ProductModalProps> = (props) => {

    const { open, closeModal, selectedProduct, onAddProduct, buttonTitleId, hideQuantityButton,
        quantityPreSelected, note, setNote, hidePrice, hideReorder, backToMenu, dealSku, forceImageDisplay,
        forceDescriptionDisplay, forcedBackToLabel, initialOptions } = props;

    const { selectedCatalog, selectedLocation, selectedTable } = useTypedSelector((state: RootState) => state.locations)
    const { data } = useTypedSelector((state: RootState) => state.authentication);

    const dispatch = useDispatch();
    const match = useRouteMatch<ROUTES.RouteParams>();
    const history = useHistory();
    const theme = useTheme();
    const intl = useIntl();

    const shouldDisplayAlcoholModal = useShowAlcoholDisclaimer(selectedProduct);
    const isEntityOrderableNowResult = isEntityOrderableNow(selectedCatalog, selectedLocation, selectedTable, selectedProduct);

    const [selectedSku, setSelectedSku] = useState<SkuExtended>({} as Sku);
    const [selectedOptions, setSelectedOptions] = useState<OrderOption[]>([])
    const [dialogWidth, setDialogWidth] = useState<string>(SMALL_DIALOG_WIDTH)
    const [backToName, setBackToName] = useState<string>("")

    const [openAlcoholModal, setOpenAlcoholModal] = useState<boolean>(false);
    const [addButtonRedirect, setAddButtonRedirect] = useState<AddButtonRedirect>();

    // The quantity that the user can chose
    const [productQuantity, setProductQuantity] = useState<number>(1);
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true)

    const [reset, setReset] = useState<boolean>(false)

    if (!open && productQuantity !== 1) {
        setProductQuantity(quantityPreSelected ?? 1);
    }

    /**
     * Set the quantity when rendering the component from the first time
     */
    useEffect(() => {
        setProductQuantity(quantityPreSelected ?? 1);
    }, []);

    /**
     * Reference of all invalid options
     */
    const [hasOptionError, setHasOptionError] = useState<OptionList[] | null>(null)
    const [showOptionError, setShowOptionError] = useState<boolean>(false)
    const isDesktopRender = useMediaQuery(theme.breakpoints.up("sm"))
    const { mobile_device } = useContext(DeviceContext)

    const handleDialogSize = useCallback(() => {
        if (selectedSku && selectedSku.option_list_refs) {
            return MEDIUM_DIALOG_WIDTH
        }
        return SMALL_DIALOG_WIDTH
    }, [selectedSku])

    useEffect(() => {
        if (selectedSku) {
            setDialogWidth(handleDialogSize())
        }
    }, [selectedSku, handleDialogSize])

    useEffect(() => {
        if (hasOptionError && hasOptionError.length > 0) {
            setIsButtonDisabled(true)
        } else {
            setIsButtonDisabled(false)
        }
    }, [selectedSku, selectedOptions])


    /**
     * Get the name of the category
     */
    useEffect(() => {
        if (selectedCatalog && selectedLocation) {
            if (forcedBackToLabel) {
                setBackToName(forcedBackToLabel)
            } else if (backToMenu) {
                setBackToName(selectedLocation.name)
            } else {
                const category: Categorie | undefined = selectedCatalog.data.categories.find((elem: { ref: string; }) => (
                    elem.ref === selectedProduct.category_ref)
                )
                if (category) {
                    setBackToName(category.name)
                }
            }
        }
    }, [backToName, selectedCatalog, forcedBackToLabel])

    /**
    * Method used when user want to add product to cart and order the same product again
    */
    const resetSelection = (): void => {
        if (hasOptionError && hasOptionError.length > 0) {
            setShowOptionError(true);
        } else if (shouldDisplayAlcoholModal) {
            setOpenAlcoholModal(true);
        } else {
            addProduct(undefined, true);
            setSelectedOptions([]);
            setProductQuantity(quantityPreSelected ?? 1);

            if (setNote) {
                setNote('')
            }
        }
        setReset(true)
    }

    const hasProductMultipleSkusOrOptions = (): boolean => {
        if (selectedProduct.skus.length > 1) {
            return true
        }

        if (selectedProduct.skus.length && productService.getProductOptionListRefs(selectedProduct)?.length) {
            return true
        }

        return false
    }

    const handleSelectedOptionsChange = (selectedOptions: OrderOption[], invalidOptionLists: OptionList[] | null) => {
        if (invalidOptionLists) {
            log.debug(`${invalidOptionLists?.length} invalid options`, invalidOptionLists);
        }
        setHasOptionError(invalidOptionLists);
        setSelectedOptions(selectedOptions);
    }

    const handleAddProductClick = (redirect: AddButtonRedirect) => {
        setAddButtonRedirect(redirect)
        if (shouldDisplayAlcoholModal) {
            setOpenAlcoholModal(true)
        } else {
            addProduct(redirect)
        }
    }

    /**
     * Add the current product with sku, options and comments to the order
     * @param staysOpened Optional boolean. If true, the product modal will not close
     */
    const addProduct = async (redirect: AddButtonRedirect | undefined, isResetSelection?: boolean) => {

        if (hasOptionError && hasOptionError.length > 0) {
            setShowOptionError(true);
        } else {

            // Not responsible for the adding to the cart
            if (onAddProduct) {
                onAddProduct(selectedProduct, selectedOptions, selectedSku, productQuantity);
            }
            // Responsible for the adding to the cart 
            else {

                const catalog = selectedCatalog

                if (catalog) {
                    const addToCartEvent: AnalyticsAddToCartEvent = analyticsHelper.getAddToCartEvenForOneItem(catalog.account_id ? catalog.account_id : "", catalog.location_id, catalog.currency, selectedProduct, selectedSku.ref, getProductPriceWithOptions(), productQuantity);
                    firebase.analytics().logEvent(firebase.analytics.EventName.ADD_TO_CART, addToCartEvent);
                }
                dispatch((OrderAction.addItem(
                    selectedProduct.ref,
                    selectedSku.ref,
                    selectedOptions,
                    catalog!,
                    selectedLocation!,
                    selectedTable,
                    selectedProduct.name,
                    productQuantity,
                    selectedProduct.skus[0].price,
                    note ?? "",
                    data.user_authentication_state.user?.uid ? data.user_authentication_state.user.uid : undefined,
                )));

                switch (redirect) {
                    // TODO: when it's the 1st item in the cart, wee see for a short time the "empty cart" page. Do the redirect in the saga instead?
                    case AddButtonRedirect.SUMMARY_PAGE: {
                        history.push(ROUTES.getOrderSummaryFullRoute(match.params.tableLinkKey));
                        break;
                    }

                    default: {
                        if (!isResetSelection) {
                            history.push(ROUTES.getCategoriesFullRoute(match.params.tableLinkKey));
                        }
                        break;
                    }
                }
            }
            closeModal();
        }
    }

    const getProductOptionsPrice = (): Money => {

        if (!selectedCatalog) {
            log.error("Missing catalog");
            return "";
        }

        const tempItem: OrderItem = {
            product_ref: selectedProduct.ref,
            product_name: selectedProduct.name,
            options: selectedOptions,
            price: selectedProduct.skus[0].price,
            quantity: productQuantity,
            sku_ref: selectedSku.ref
        }

        const optionsPrice: Money = numberToMoney(
            consolidateOptionsInfo(tempItem, selectedProduct, selectedCatalog.data.option_lists, selectedCatalog.data.options),
            selectedCatalog.currency
        );

        return optionsPrice;
    }

    /**
     * Get the price of the product with its selected skus and options
     */
    const getProductPriceWithOptions = (): Money => {

        if (!selectedCatalog || !selectedSku.price) {
            return "";  // TODO: return undefined and handle PROPERLY this case. // GOTO 5DR3
        }

        const optionsPrice = getProductOptionsPrice();
        const skuPriceMaybeReduced: Money = selectedSku.reduced_price ? selectedSku.reduced_price : selectedSku.price

        const finalPricePerItem: Money = addMoney(
            optionsPrice,
            skuPriceMaybeReduced
        )

        const finalPrice: Money = multiplyMoney(finalPricePerItem, productQuantity);
        return finalPrice;
    }

    const addQuantity = () => {
        setProductQuantity(productQuantity + 1);
    }

    const removeQuantity = () => {
        if (productQuantity > 1) {
            setProductQuantity(productQuantity - 1);
        }
    }

    const getImage = (product: Product) => {
        if (product.image) {
            return product.image
        } else if (selectedCatalog) {
            const image = selectedCatalog.data.categories.find(cat => cat.ref === product.category_ref)?.icon
            return image ? image : DefaultBackground
        } else {
            return DefaultBackground
        }
    }

    return (
        <Dialog
            open={open}
            onClose={closeModal}
            scroll={mobile_device ? 'body' : 'paper'}
            PaperProps={{
                style: mobile_device ? { ...mobilePaperProps } : {
                    backgroundColor: theme.palette.background.paper,
                    width: dialogWidth,
                    maxWidth: "unset",
                },
            }}
            fullScreen={
                selectedProduct && selectedProduct.skus
                    && (selectedProduct.skus.length > 1 || productService.getProductOptionListRefs(selectedProduct)?.length)
                    && !isDesktopRender ? true : false
            }
        >
            <AlcoholDisclaimerModal
                open={openAlcoholModal}
                onClose={() => setOpenAlcoholModal(false)}
                onAccept={() => {
                    addProduct(addButtonRedirect, true);
                    setOpenAlcoholModal(false);
                }}
            />

            <Box display='flex' flexDirection='column' data-test="product.modal_choose_options" >
                {!mobile_device &&
                    <IconButton
                        onClick={closeModal}
                        style={{
                            alignSelf: 'end',
                            padding: 0,
                            marginTop: theme.spacing(2),
                            marginRight: theme.spacing(2),
                        }}
                        size="large">
                        <CloseRoundedIcon />
                    </IconButton>
                }
                <Box style={{ padding: 0 }}>

                    {mobile_device &&
                        <DisplayHeader
                            selectedProductOrDeal={selectedProduct}
                            closeModal={closeModal}
                            backToTitle={backToName}
                        />
                    }

                    {!mobile_device && forceImageDisplay &&
                        <img
                            src={getImage(selectedProduct)}
                            alt={selectedProduct.name}
                            style={{
                                height: '250px',
                                width: '100%',
                                objectFit: 'cover'
                            }}
                        />
                    }

                    {(showOptionError && hasOptionError && hasOptionError.length) &&

                        <Alert severity="error">

                            <AlertTitle>
                                <Typography variant="h5" color="error">
                                    {intl.formatMessage({ id: "options.hasError.title" })}
                                </Typography>
                            </AlertTitle>

                            <Typography variant="h5" color="error">
                                {intl.formatMessage({ id: "options.hasError.message" })}
                            </Typography>

                        </Alert>
                    }
                </Box>

                <Box
                    p={0}
                    flexGrow={3}
                    display='flex'
                    flexDirection='column'
                >
                    <ProductOptions
                        selectedProduct={selectedProduct}
                        selectedSku={selectedSku}
                        setSkuSelected={setSelectedSku}
                        selectedOptions={selectedOptions}
                        onSelectedOptionsChange={handleSelectedOptionsChange}
                        reset={reset}
                        setReset={setReset}
                        dealSku={dealSku}
                        optionError={hasOptionError}
                        forceDescriptionDisplay={forceDescriptionDisplay}
                        hideSkuPrice={Boolean(dealSku)}
                        initialOptions={initialOptions}
                    />

                    {/* Customer comments */}

                    {
                        selectedProduct.allow_customer_notes
                        && setNote
                        && isEntityOrderableNowResult
                        &&

                        <Box p={2} px={mobile_device ? 2 : 6} mt={4}>
                            <TextField
                                placeholder={intl.formatMessage({ id: 'Order.cart.customer_notes.dialog.placeholder' })}
                                fullWidth={true}
                                multiline
                                InputProps={{ disableUnderline: true }}
                                value={note}
                                onChange={e => setNote(e.target.value)}
                                style={{ width: '100%', border: 'none' }}
                            />
                        </Box>
                    }

                </Box>

                <Box p={2}>

                    <AddProductButton
                        hideQuantityButton={hideQuantityButton}
                        buttonTitleId={buttonTitleId}
                        onAddProduct={handleAddProductClick}
                        price={dealSku ? `+${MoneyToStringWithSymbol(getProductOptionsPrice())}` : getProductPriceWithOptions()}
                        onAdd={addQuantity}
                        onRemove={removeQuantity}
                        quantity={productQuantity}
                        hidePrice={hidePrice}
                        isButtonDisabled={isButtonDisabled}
                        product={selectedProduct}
                        addToDeal={Boolean(dealSku)}
                        displayFastCheckoutButton={!Boolean(dealSku)}
                    />

                    {/* Add to cart and order again button */}
                    {
                        !hideReorder
                        && isEntityOrderableNowResult
                        && hasProductMultipleSkusOrOptions()
                        &&

                        <Box
                            onClick={resetSelection}
                            style={{ cursor: 'pointer' }}
                            display='flex'
                            justifyContent='center'
                            px={2}
                            my={4}
                        >
                            <Typography
                                variant='body1'
                                color='secondary'
                                align='center'
                                style={{ textDecoration: 'underline' }}
                            >
                                {
                                    intl.formatMessage({ id: buttonTitleId ? buttonTitleId : 'Order.addButton.add' })
                                    + ' '
                                    + productQuantity
                                    + ' '
                                    + intl.formatMessage({ id: 'Order.toCart' })
                                    + ' '
                                    + intl.formatMessage({ id: 'Order.orderAgain' })
                                }
                            </Typography>
                        </Box>
                    }
                </Box>
            </Box>
        </Dialog>
    );
}

export default ProductModal;
